(function () {
  'use strict';

  controller.$inject = ['$state'];

  function controller($state) {
    function link(scope, element, attrs) {
      const variable = attrs.getStateParamsToScopeOld || 'stateParams';

      scope[variable] = {};
      scope[variable].__proto__ = $state.params;
      scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        scope[variable].__proto__ = toParams;
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive('getStateParamsToScopeOld', controller);
})();

