(function () {
  'use strict';

  const directive = { name: 'campobetCashboxDefaultAmount' };

  controller.$inject = [];

  function controller() {
    function link(scope) {
      scope.formData.amount = scope.pc.paySystem.rules.default ?? scope.pc.paySystem.rules.range[0];
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
