(function () {
  'use strict';

  const directive = { name: 'closeSidebar' };


  controller.$inject = ['$rootScope'];

  function controller($rootScope) {
    function link(scope, element, attrs) {
      const sidebarCollapsed = document.body.classList.contains('sidemenu-collapsed')

      if (!sidebarCollapsed) {
        document.body.classList.add('sidemenu-collapsed');
      }

      scope.$on('$destroy', () => {
        if (!sidebarCollapsed) {
          document.body.classList.remove('sidemenu-collapsed');
        }
      })
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
