import playgrounds from '../../../app/service/gamification/playgrounds.js';
import campobetCollectionServices from './campobet-collections-service.js';
import { filter } from 'rxjs/operators';
(function () {
  'use strict';

  const component = {
    name: 'campobetCollectionsItem',
  };

  controller.$inject = ['$scope', '$state', 'campobetCollectionServices', 'system$', 'state'];

  function controller($scope, $state, campobetCollectionServices, system$, _state) {
    const collection$ = campobetCollectionServices.collection$;
    const currentCardAlias = $state.params.name;

    const subscription = collection$.subscribe((collection) => {
      $scope.collection = collection;
      $scope.currentCardAlias = currentCardAlias;
      $scope.currentCard = $scope.collection.find((el) => el.alias === currentCardAlias);
      $scope.currentCollection = $scope.collection.find((el) => el.alias !== 'em_2024' && el.status === 'in_progress');
    });

    const systemSubscription = system$
      .pipe(
        filter(({ action }) => action === 'login')
      )
      .subscribe(() => {
        _state.goto('trophyHall');
      });

    this.$onInit = () => {};

    this.$onDestroy = () => {
      subscription.unsubscribe();
      systemSubscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      mode: '<',
    },
  });
})();
