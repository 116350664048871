import campobetCollectionServices from './campobet-collections-service.js';

(function () {
  'use strict';

  const component = {
    name: 'campobetCollections',
  };

  controller.$inject = ['$scope', 'campobetCollectionServices'];

  function controller($scope, campobetCollectionServices) {
    const collection$ = campobetCollectionServices.collection$;

    const subscription = collection$.subscribe((collection) => {
      $scope.collection = collection;
    });

    this.$onInit = () => {};

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      mode: '<',
    },
  });
})();
