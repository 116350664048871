import analytics from '../../app/service/analytics/analytics.js';

(function () {
  'use strict';

  const directive = { name: 'track' };

  controller.$inject = ['analytics'];

  function controller(_analytics) {
    function link(scope, element, attrs) {
      const handleClick = () => {
        _analytics.send(attrs.gCategory, attrs.gAction, attrs.gLabel);
      };
      element[0].addEventListener('click', handleClick);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('scroll', handleClick);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
