import { gsap } from 'gsap';
import ScrollTrigger from '../../app/libs/gsap/ScrollTrigger.min.js';

(function () {
  'use strict';

  const directive = { name: 'campobetToggleCtaColor' };

  function controller() {
    const root = document.documentElement;

    const removeCta = () => {
      root.style.setProperty('--bankid-call-button-bg', '#FC8D0A');
      root.style.setProperty('--bankid-call-button-bg-hover', '#f26702');
    };

    const addCta = () => {
      root.style.setProperty('--bankid-call-button-bg', '#01b93c');
      root.style.setProperty('--bankid-call-button-bg-hover', '#82b435');
    };

    function link(scope, element, attrs) {
      gsap.registerPlugin(ScrollTrigger);

      const scrollerElement = document.querySelector('.page-wrapper__content');

      removeCta();

      scope.scr = ScrollTrigger.create({
        trigger: element,
        scroller: scrollerElement,
        start: 'top top',
        end: 'bottom top',
        onLeave: () => addCta(),
        onLeaveBack: () => removeCta(),
      });

      scope.$on('$destroy', () => {
        scope.scr.kill(false);
        addCta();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
