(function () {
  'use strict';

  const directive = {
    name: 'getLocalStorage'
  };

  function controller() {
    function link( scope, element, attrs ){
      scope.pc.tab = scope.pc.tab ?? localStorage.getItem('loginCurrentTab');
    }

    return {
      restrict: 'A',
      link:link
    };
  }

  app.directive( directive.name, controller );
})();
