(function () {
  'use strict';
  const directive = { name: 'campobetCloseLoginPopup' };

  controller.$inject = ['popup'];

  function controller(_popup) {
    function link() {
      _popup.close();
    }
    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
