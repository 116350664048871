(function () {
  'use strict';

  const directive = { name: 'scrollElementToTop' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const toTopElem = document.querySelector(`${attrs[directive.name]}`);

      const handleClick = function () {
        toTopElem?.scrollTo(0, 0);
      };

      element[0].addEventListener('click', handleClick);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('scroll', handleClick);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
