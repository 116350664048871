import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'closeFromFrame' };

  controller.$inject = ['popup', 'trustly$'];

  function controller(
    _popup,
    _trustly$
  ) {

    function link(scope){
      const subscription = _trustly$
        .pipe(
          filter(({ action }) => action === 'step')
        )
        .subscribe(({ data }) => {
          if (data === -1) {
            _popup.close();
            return;
          }
        });


      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      })
    }

    return {
      restrict: 'A',
      link:link
    };
  }

  app.directive( directive.name, controller );

})();
