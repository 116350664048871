const router = [
  // ---- ROOT COMPONENT ----
  {
    name: 'app.root',
    abstract: true,
    template: '<linda-app linda-zendesk></linda-app>',
  },

  // ---- CASINO PAGES ----
  {
    name: 'app.root.c',
    abstract: true,
    template: '<ui-view></ui-view>',
    params: {
      theme: 'casino',
      page: 'categories',
      // for pages with category menu
      sub_header: true,
    },
  },
  {
    name: 'app.root.c.casino',
    url: '/casino',
    template: '<linda-casino-section></linda-casino-section>',
  },
  {
    name: 'app.root.c.casino_duplicate',
    url: '/casino/',
    template: '<linda-casino-section></linda-casino-section>',
  },
  {
    name: 'app.root.c.category',
    url: '/casino/:name',
    template: '<batman-game-hall1></batman-game-hall1>',
    params: {
      page: 'categories',
    },
  },
  // ---- PROVIDERS PAGES ----
  {
    name: 'app.root.providers',
    url: '/casino-providers',
    template: '<batman-collection-list1></batman-collection-list1>',
  },
  {
    name: 'app.root.provider',
    url: '/casino-providers/:name',
    template: '<batman-game-hall2></batman-game-hall2>',
    params: {
      is_provider: true,
      page: 'categories',
      sub_header: true,
      theme: 'casino',
    },
  },
  // ---- LAST PLAYED PAGE ----
  {
    name: 'app.root.continue',
    url: '/games/continue-playing',
    template: '<batman-game-hall3></batman-game-hall3>',
    params: {
      page: 'categories',
      sub_header: true,
      theme: 'casino',
    },
  },
  // ---- FAVOURITES PAGE ----
  {
    name: 'app.root.favourite',
    url: '/games/my-games',
    template: '<batman-game-hall4></batman-game-hall4>',
    params: {
      page: 'categories',
      sub_header: true,
      theme: 'casino',
    },
  },
  // ---- LIVE CASINO PAGES ----
  {
    name: 'app.root.c1',
    abstract: true,
    template: '<ui-view></ui-view>',
    params: {
      theme: 'live-casino',
      page: 'categories',
      // for pages with category menu
      sub_header: true,
    },
  },
  {
    name: 'app.root.c1.live_casino',
    url: '/live-casino',
    template: '<batman-game-hall5></batman-game-hall5>',
    params: {
      name: 'live-casino',
      page: 'categories',
    },
  },
  {
    name: 'app.root.c1.live_casino_duplicate',
    url: '/live-casino/',
    template: '<batman-game-hall5></batman-game-hall5>',
    params: {
      name: 'live-casino',
      page: 'categories',
    },
  },
  {
    name: 'app.root.c1.category_live',
    url: '/live-casino/:name',
    template: '<batman-game-hall1></batman-game-hall1>',
    params: {
      theme: 'live-casino',
      page: 'categories',
      footerGroup: 3,
    },
  },
  // ---- JACKPOTS PAGES ----
  {
    name: 'app.root.c2',
    abstract: true,
    template: '<ui-view></ui-view>',
    params: {
      theme: 'jackpots',
      page: 'categories',
      // for pages with category menu
      sub_header: true,
    },
  },
  {
    name: 'app.root.c2.jackpots',
    url: '/jackpots',
    template: '<batman-game-hall6></batman-game-hall6>',
    params: {
      name: 'jackpots',
      page: 'categories',
    },
  },
  {
    name: 'app.root.c2.jackpots_duplicate',
    url: '/jackpots/',
    template: '<batman-game-hall6></batman-game-hall6>',
    params: {
      name: 'jackpots',
      page: 'categories',
    },
  },
  {
    name: 'app.root.c2.category_jackpots',
    url: '/jackpots/:name',
    template: '<batman-game-hall1></batman-game-hall1>',
    params: {
      theme: 'jackpots',
      page: 'categories',
      footerGroup: 4,
    },
  },

  // ---- GAME PAGE ----
  {
    name: 'app.root.real',
    url: '/play/:name',
    template: '<linda-game-page-mobile></linda-game-page-mobile>',
    private: true,
    params: {
      mode: 'real',
      no_header: true,
      no_seotext: true,
      no_footer: true,
    },
  },
  {
    name: 'app.root.demo',
    url: '/game/:name',
    template: '<linda-game-page-mobile></linda-game-page-mobile>',
    params: {
      mode: 'demo',
      no_header: true,
      no_seotext: true,
      no_footer: true,
    },
  },
  {
    name: 'app.root.game-demo',
    url: '/game/:name/demo',
    template: '<linda-game-page-mobile></linda-game-page-mobile>',
    params: {
      mode: 'demo',
      no_header: true,
      no_seotext: true,
      no_footer: true,
    },
  },

  // ---- PAYMENT CALLBACK PAGES ----
  {
    name: 'app.root.paymentcb',
    url: '/payment/:act?order_id&identifier',
    template: '<linda-payment-callback />',
    params: {
      type: 'deposit',
      no_header: true,
      no_seotext: true,
      no_footer: true,
      noMinWidth: true
    }
  },
  {
    name: 'app.root.withdraw-paymentcb',
    url: '/withdraw-payment/:act',
    template: '<linda-payment-callback />',
    params: {
      type: 'withdrawal',
      no_header: true,
      no_seotext: true,
      no_footer: true,
      noMinWidth: true
    }
  },
  {
    name: 'app.root.unsubscribe',
    url: '/unsubscribe/:act',
    template: '<linda-unsubscribe-page ></linda-unsubscribe-page>',
  },
  {
    name: 'app.root.deposit-page',
    url: '/deposit-page',
    template: '<sweden-initial-limit-page></sweden-initial-limit-page>',
    private: true,
  },
  {
    name: 'app.root.time-page',
    url: '/time-page',
    template:
      '<sweden-initial-time-limit-page></sweden-initial-time-limit-page>',
    private: true,
  },
  {
    name: 'app.root.home',
    url: '/',
    template: '<linda-homepage></linda-homepage>',
    params: {
      page: 'home'
    }
  },

  // ---- SPORTBOOK PAGES ----
  {
    name: 'app.root.sportpage',
    url: '/sport',
    template: '<linda-sport-page></linda-sport-page>',
  },
  {
    name: 'app.root.sportpage.prelive',
    url: '/prelive?sportids&catids&champids&tabs&eventid&betslipoddids&page',
    template: '',
    params: {
      page: 'overview',
    },
  },
  {
    name: 'app.root.sportpage.live',
    url: '/live?sportids&catids&champids&tabs&eventid&betslipoddids&page',
    template: '',
    params: {
      page: 'live',
    },
  },
  {
    name: 'app.root.sportpage.else',
    url: '/virtual?sportids&catids&champids&tabs&eventid&betslipoddids&page',
    template: '',
    params: {
      page: 'virtual',
    },
  },

  // ---- PAGE 404 ----
  {
    name: 'app.root.not-found',
    url: '/404',
    template: '<not-found></not-found>',
    params: {
      noPagePaddingBottom: true,
    },
  },

  // ---- APPLICATION PAGE ----
  {
    name: 'app.root.application',
    url: '/application',
    template: '<batman-info-page3 to-top-on-state-change />',
    params: {
      pageName: 'info',
      name: 'application',
    },
  },

  // ---- PAYMENTS PAGE ----
  {
    name: 'app.root.payments',
    url: '/payments',
    template: '<batman-banking-page1></batman-banking-page1>',
  },



  // ---- INFO PAGES ----
  {
    name: 'app.root.info',
    abstract: true,
    template: '<linda-view-layer-two></linda-view-layer-two>',
  },
  {
    name: 'app.root.info.about',
    url: '/about',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'about',
    },
  },
  {
    name: 'app.root.info.contact-us',
    url: '/contact-us',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'contact-us',
    },
  },
  {
    name: 'app.root.info.faq',
    url: '/faq',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'faq',
    },
  },
  {
    name: 'app.root.info.rules',
    url: '/rules',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'rules',
    },
  },
  {
    name: 'app.root.info.complaints',
    url: '/complaints',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'complaints',
    },
  },
  {
    name: 'app.root.info.privacy-policy',
    url: '/privacy-policy',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'privacy-policy',
    },
  },
  {
    name: 'app.root.info.responsible-gaming',
    url: '/responsible-gaming',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming',
    },
  },
  {
    name: 'app.root.info.cookie-policy',
    url: '/cookies-policy',
    template: '<linda-info-page></linda-info-page>',
    params: {
      pageName: 'info',
      name: 'cookies-policy',
    },
  },
  {
    name: 'app.root.info.sitemap',
    url: '/sitemap',
    template: '<linda-sitemap></linda-sitemap>',
  },

  // ---- ACCOUNT PROFILE PAGES ----
  {
    name: 'app.root.account',
    abstract: true,
    template: '<linda-profile-page></linda-profile-page>',
    private: true,
  },
  {
    name: 'app.root.account.details',
    url: '/account/details',
    template: '<linda-profile-form></linda-profile-form>',
  },
  {
    name: 'app.root.account.verification',
    url: '/account/verification',
    template: '<dvs-page></dvs-page>',
  },
  {
    name: 'app.root.account.bonuses',
    url: '/account/bonuses',
    template: '<linda-profile-bonus-list></linda-profile-bonus-list>',
  },
  {
    name: 'app.root.account.balance-history',
    url: '/account/balance-history',
    template: '<balance-history></balance-history>',
  },
  {
    name: 'app.root.account.responsible-gaming',
    url: '/account/responsible-gaming',
    template: '<sweden-profile-limits></sweden-profile-limits>',
    params: {
      pageName: 'info',
      name: 'responsible-gaming-logged',
    },
  },
  // ----  PROMO PAGES ----
  {
    name: 'app.root.promo',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.promo.list',
    url: '/promotions',
    template: '<linda-promos></linda-promos>',
  },
  {
    name: 'app.root.promo.page',
    url: '/promotions/:name',
    template: '<linda-promo-page></linda-promo-page>',
  },

  // ---- TROPHY HALL PAGES ----
  {
    name: 'app.root.trophy',
    abstract: true,
    template: '<ui-view></ui-view>',
  },
  {
    name: 'app.root.trophy.hall',
    url: '/collections',
    template: '<campobet-collections></campobet-collections>',
  },
  {
    name: 'app.root.trophy.collection',
    url: '/collections/:name',
    template: '<campobet-collections-item></campobet-collections-item>',
  },

  // ---- MISSED DATA ----
  {
    name: 'app.root.missedData',
    url: '/missed-data',
    private: true,
    template: '<linda-missed-data-form />',
  },

  // ---- UI PAGES ---- TODO: REMOVE ON PROD
  {
    name: 'app.root.ui1_icons',
    url: '/ui-icons',
    template: '<batman-ui-wrapper1></batman-ui-wrapper1>',
    params: {
      no_header: true,
      no_footer: true,
      noPagePaddingBottom: true,
    },
  },
  {
    name: 'app.root.ui2_btns',
    url: '/ui-btns',
    template: '<batman-ui-wrapper2></batman-ui-wrapper2>',
    params: {
      no_header: true,
      no_footer: true,
      noPagePaddingBottom: true,
    },
  },
  {
    name: 'app.root.ui3_typography',
    url: '/ui-typography',
    template: '<batman-ui-wrapper3></batman-ui-wrapper3>',
    params: {
      no_header: true,
      no_footer: true,
      noPagePaddingBottom: true,
    },
  },
  {
    name: 'app.root.ui4_inputs',
    url: '/ui-inputs',
    template: '<batman-ui-wrapper4></batman-ui-wrapper4>',
    params: {
      no_header: true,
      no_footer: true,
      noPagePaddingBottom: true,
    },
  },
  // ---- TEST LOGIN
  {
    name: 'app.root.ui8',
    url: '/ui8',
    template: '<batman-ui-wrapper8></batman-ui-wrapper8>'
  },
];

export { router };
