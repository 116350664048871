// https://mymth.github.io/vanillajs-datepicker/#/
(function () {
  'use strict';

  const directive = { name: 'datePicker' };

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    const datepickerOptions = {
      autohide: true,
      format: 'dd/mm/yyyy',
      maxDate: 'today',
      prevArrow: '',
      nextArrow: '',
      todayHighlight: true,
      allowOneSidedRange: true,
      container: '.balance-filter__wrap',
    };

    function link($scope, $element) {
      const element = $element[0];

      let datepickerInstance = null;

      const updateModel = () => {
        const from = datepickerInstance.getDates()[0].toISOString();
        const to = new Date(datepickerInstance.getDates()[1].setHours(23, 59, 59, 999)).toISOString();

        $timeout(() => {
          $scope.selectedDates = {
            dateBegin: from,
            dateEnd: to,
          }
        });
      };

      const initDatepicker = () => {
        datepickerInstance = new DateRangePicker(element, datepickerOptions);
        datepickerInstance.setDates('today', 'today');
        datepickerInstance.inputs.forEach((input) => {
          input.addEventListener('changeDate', updateModel);
        });

        updateModel();
      };

      const destroyDatepicker = () => {
        datepickerInstance.destroy();
      };

      const reinitDatepicker = () => {
        if (datepickerInstance) {
          destroyDatepicker();
        }

        initDatepicker();
      };

      $scope.$watch('formData.type', () => {
        reinitDatepicker();
      });

      $scope.$on('$destroy', () => {
        destroyDatepicker();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
