import http from '../../../app/service/domain/http.js';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';


(function () {
  controller.$inject = [
    '$q',
    'http'
  ];

  const SERVICE_NAME = 'balanceHistoryService';
  const ALIASES = {
    historyFilters: 'balanceHistoryFilters',
    historyTrnsactions: 'balanceHistory'
  };

  const availableFilterTypes = [];
  const availableFilterOptions = [];

  const availableFilterTypes$ = new BehaviorSubject([]);
  const selectedFilter$ = new BehaviorSubject(null);
  const availableFilterOptions$ = new BehaviorSubject([]);

  function controller($q, _http) {
    function filters() {
      const defer = $q.defer();
      const config = {
        cache: true,
        meta: {
          type: 'api',
          alias: ALIASES.historyFilters
        },
      };

      _http
        .get(ALIASES.historyFilters, config)
        .then(({ data: { result } }) => {
          defer.resolve(result);
        })
        .catch(e => defer.reject);

      return defer.promise;
    }

    function transactions(param = '') {
      const defer = $q.defer();
      const config = {
        cache: true,
        meta: {
          type: 'api',
          alias: ALIASES.historyTrnsactions
        },
      };
      const alias = {
        name: ALIASES.historyTrnsactions,
        param
      }

      _http
        .get(
          alias,
          config
        )
        .then(({ data }) => {
          defer.resolve(data);
        })
        .catch(e => defer.reject);

      return defer.promise;
    }

    function getFilterOptions(filters) {
      return filters.reduce((acc, cur) => {
        cur.filters.forEach(element => {
          acc.push({
            ...element,
            type: cur.type
          })
        });
        return acc
      }, [])
    }

    function getFilterTypes (filters) {
      return filters.map((filter) => filter.type);
    }

    filters().then(response => {
      availableFilterTypes$.next(getFilterTypes(response))
      availableFilterOptions$.next(getFilterOptions(response))
    })

    return {
      filters,
      transactions,
      filterTypes: availableFilterTypes$.asObservable(),
      selectedFilterType: selectedFilter$,
      filterOptions: availableFilterOptions$.asObservable()
    };
  }

  app.service(
    SERVICE_NAME,
    controller
  );
})();
