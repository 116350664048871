import balanceHistoryService from './balance-history-service.js';

(function () {
  'use strict';

  const DIRECTIVE_NAME = 'balanceHistoryFilterType';

  controller.$inject = [
    'balanceHistoryService',
    '$translate'
  ];

  const exclude = [
    'horse_racing'
  ]

  function controller(
    _balanceHistoryService,
    $translate
  ) {
    function link(
      $scope,
      $element,
      $attrs,
      $ctrl,
      $transclude
    ) {
      if (!$scope.ngModel) $scope.ngModel = undefined;

      let previousElement = $element[0];

      const buildFilterTypes = (filterTypes) => {
        let prevNode

        filterTypes = filterTypes.filter((item) => !exclude.includes(item));

        filterTypes.forEach((type, idx) => {
          $transclude(
            $scope.$new(true),
            ($cloneElement, scope) => {
              const cloneElement = $cloneElement[0];

              scope.index = idx;
              scope.type = type;

              const hash = Date.now().toString(36);
              const checkbox = document.createElement('input');

              checkbox.setAttribute("type", "radio");
              checkbox.setAttribute('value', `${type}`);
              checkbox.setAttribute('id', `${hash}${idx}`);
              checkbox.setAttribute('hidden', 'true');
              checkbox.name = 'filters';

              const label = document.createElement('label');
              //const labelText = document.createTextNode(type);

              const labelText = document.createTextNode(
                //`filter.transaction.type.${type}`

                $translate.instant(`filter.transaction.type.${type}`)
              );

              label.setAttribute('for', `${hash}${idx}`);
              label.appendChild(labelText);
              label.setAttribute("value", type);
              label.classList.add('balance-filter__btn')

              checkbox.addEventListener('click', ({ target }) => {
                $scope.$apply(() => {
                  const uncheck = target.checked && $scope.ngModel === target.value;

                  if (uncheck) {
                    $scope.ngModel = undefined;
                    target.checked = false;
                  } else {
                    $scope.ngModel = target.value;
                  }

                  _balanceHistoryService.selectedFilterType.next($scope.ngModel)
                });
              });

              cloneElement.append(checkbox)
              cloneElement.append(label)

              previousElement.after(cloneElement);
              previousElement = cloneElement;
            });
        });
      }
      const subscription =_balanceHistoryService
        .filterTypes
        .subscribe(buildFilterTypes);
    }

    return {
      require: 'ngModel',
      restrict: 'A',
      transclude: 'element',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(
    DIRECTIVE_NAME,
    controller
  );
})();
