(function () {
  'use strict';

  const directive = { name: 'toTopOnStateChange' };

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element) {
      scope.$on('$stateChangeStart', () => {
        $timeout(() => {
          element.duScrollTopAnimated(0, 1);
        },0);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
