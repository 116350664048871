(function () {
  'use strict';

  const directive = { name: 'campobetScrollIntoView' };

  controller.$inject = [];

  function controller() {
    function link(scope, element, attrs) {
      const { selector, class: className } = scope.$eval(attrs[directive.name]);
      const observer = new MutationObserver(scroll);
      const { classToScroll: targetClass = className, itemClass: itemContainerClass = selector } = scope.$eval(
        attrs[directive.name]
      );

      function scroll(mutations) {
        for (const mutation of mutations) {
          if (
            mutation.target.classList.contains(targetClass) &&
            mutation.target.classList.contains(itemContainerClass)
          ) {
            mutation.target.scrollIntoView(false);
          }
        }
      }

      scope.$on('$destroy', () => {
        observer.disconnect();
      });
      observer.observe(element[0], {
        childList: true,
        attributes: true,
        subtree: true,
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
