const $_PGW = {
  enabled: true,
  layoutMode: 'row',
  hideCloseButton: true,
  cssVars: {
    "--pgw-font-family": "Rubik",
    "--pgw-background-primary": "#F2F5F3",
    "--pgw-surface-primary": "#F2F5F3",
    "--pgw-surface-secondary": "#FFFFFF",
    "--pgw-surface-secondaryHover": "#FBF2E5",
    "--pgw-surface-accent": "#F8E6D8",
    "--pgw-surface-accentHover": "#F9D1B3",
    "--pgw-surface-buttonPrimary": "#01B93C",
    "--pgw-surface-buttonPrimaryHover": "#82B435",
    "--pgw-surface-buttonPrimaryPress": "#68902A",
    "--pgw-surface-buttonPrimaryDisabled": "#8EE5AA",
    "--pgw-surface-buttonSecondary": "#FC8D0A",
    "--pgw-surface-buttonSecondaryHover": "#F26702",
    "--pgw-surface-buttonSecondaryPress": "#D25800",
    "--pgw-surface-buttonSecondaryDisabled": "#FDC684",
    "--pgw-surface-buttonTertiary": "#E2E2E2",
    "--pgw-surface-buttonTertiaryHover": "#D5D5D5",
    "--pgw-surface-buttonTertiaryPress": "#CCCCCC",
    "--pgw-surface-buttonTertiaryDisabled": "#F6F6F9",
    "--pgw-surface-inputPrimary": "#FFFFFF",
    "--pgw-surface-inputSecondary": "#FFFFFF",
    "--pgw-surface-inputTertiary": "#F0F0F4",
    "--pgw-surface-tab": "#FFFFFF",
    "--pgw-surface-tabHover": "#31E488",
    "--pgw-surface-tabPress": "#068946",
    "--pgw-surface-negative": "#EE0E0E",
    "--pgw-surface-positiveAlpha": "#01B93C1A",
    "--pgw-surface-skeleton": "#E7E7E7",
    "--pgw-surface-userchoiсePrimary": "#01B93C",
    "--pgw-surface-userchoiсeSecondary": "#01B93C",
    "--pgw-surface-userchoiсeTertiary": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorPrimary": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorPrimaryHover": "#F9F8F6",
    "--pgw-surface-userchoiсeIndicatorSecondary": "#01B93C",
    "--pgw-surface-userchoiсeIndicatorSecondaryHover": "#82B435",
    "--pgw-text-primary": "#131325",
    "--pgw-text-secondary": "#0F492C",
    "--pgw-text-tertiary": "#D2D2D2",
    "--pgw-text-buttonPrimary": "#FFFFFF",
    "--pgw-text-buttonPrimaryDisabled": "#FFFFFF",
    "--pgw-text-buttonSecondary": "#FFFFFF",
    "--pgw-text-buttonSecondaryDisabled": "#FFFFFF",
    "--pgw-text-positive": "#24A820",
    "--pgw-text-warning": "#FF9314",
    "--pgw-text-negative": "#EE0E0E",
    "--pgw-text-constant": "#FFFFFF",
    "--pgw-icon-primary": "#131325",
    "--pgw-icon-secondary": "#0F492C",
    "--pgw-icon-tertiary": "#D2D2D2",
    "--pgw-icon-buttonPrimary": "#FFFFFF",
    "--pgw-icon-buttonPrimaryDisabled": "#FFFFFF",
    "--pgw-icon-buttonSecondary": "#FFFFFF",
    "--pgw-icon-positive": "#24A820",
    "--pgw-icon-warning": "#FF9314",
    "--pgw-icon-negative": "#EE0E0E",
    "--pgw-stroke-primary": "#E7E7E7",
    "--pgw-stroke-secondary": "#E2E2E9",
    "--pgw-stroke-inputPrimary": "#A4A6A7",
    "--pgw-stroke-inputSecondary": "#A4A6A7",
    "--pgw-stroke-accent": "#F26702",
    "--pgw-stroke-accentHover": "#C45200",
    "--pgw-stroke-positive": "#24A820",
    "--pgw-stroke-positiveAlpha": "#24A8204d",
    "--pgw-stroke-warning": "#FF9314",
    "--pgw-stroke-negative": "#EE0E0E",
    "--pgw-stroke-userchoiсe": "#01B93C",
    "--pgw-stroke-userchoiсeHover": "#82B435",
    "--pgw-divider-primary": "#D2D2D2",
    "--pgw-border-radius-s": "4px",
    "--pgw-border-radius-m": "8px",
    "--pgw-border-radius-l": "12px",
    "--pgw-border-radius-xl": "16px"
  },
};

export { $_PGW };

