import banner from '../../../app/service/domain/banner.js';
import localStorage from '../../../app/service/localStorage.js'
import { filter, takeUntil, take } from 'rxjs/operators'

const componentName = 'smartBannerBtn';

(function() {
  'use strict';

  controller.$inject = [
    '$scope',
    'banner',
    'localStorage',
    '$window',
    '$timeout',
  ];

  function controller(
    $scope,
    _banner,
    _localStorage,
    $window,
    $timeout,
  ) {
    const conf = {
      category: 'banner',
      smartbannerOpenClass: 'smartbanner-open',
      defaultIos: 'smartbanner_ios',
      defaultAndroid: 'smartbanner_android',
      lsParamsKey: 'params',
      lsShownKey: 'bannerLastShown',
    }

    this.$onInit = () => {
      $scope.ios = this.ios
      $scope.android = this.android
      $scope.showOnly = this.showOnly
      $scope.banner

      displyBanner()
    };

    function getLink(bannerUrl) {
      const storeUrl = new URL(bannerUrl)
      const storeUrlbase = `${storeUrl.origin}${storeUrl.pathname}`
      const storeUrlparams = new URLSearchParams(storeUrl.search)
      const savedParams = _localStorage.getObject(conf.lsParamsKey)
      const campaignIsValid = /^M[0-9]+_[0-9]+_/.test(savedParams.c)

      if (savedParams.mid && savedParams.c && !campaignIsValid) {
        savedParams.c = `M${savedParams.mid}_${savedParams.c}`
        savedParams.af_channel = 'Affiliates'
      }
      else if (savedParams.mid && !campaignIsValid) {
        savedParams.c = `M${savedParams.mid}_`
        savedParams.af_channel = 'Affiliates'
      }

      for (const [key, value] of Object.entries(savedParams)) {
        storeUrlparams.set(key, value)
      }

      return `${storeUrlbase}?${storeUrlparams}`
    }

    async function getBanner(config) {
      $scope.preloader = true

      const { result:  [banner]  } = await _banner
        .collection(config)
      $scope.preloader = false

      return banner
    }

    function getPlatform() {
      return $scope.android || $scope.ios
    }

    async function displyBanner() {
        $scope.mobileDevice = getPlatform()
        $scope.banner = await getBanner({
          category: conf.category,
          place: $scope.mobileDevice
        })

        if (!$scope.banner) return
    }

    $scope.open = () => {
      const storeUrl = getLink($scope.banner.url)
      $window.location.href = storeUrl
    }
  }

  new Controller(componentName, controller, {
    android: '<',
    ios: '<',
  });
})();

(function() {
  [1, 2, 3, 4, 5].forEach((i) => {
    new Batman(`${componentName}${i}`, componentName);
  });
})();
