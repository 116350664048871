import state from '../../app/service/domain/state.js';

(function () {
  'use strict';

  const directive = { name: 'back' };

  controller.$inject = ['$window', 'state'];

  function controller($window, _state) {
    function link(scope, element) {
      const handleClick = () => {
        const isLive = scope?.game?.collections?.flat()?.includes('live-casino-categories');
        if ($window.history.length > 2) {
          $window.history.back();
        } else if (isLive) {
          _state.goto('liveCasino');
        } else {
          _state.goto('casino');
        }
      };
      element[0].addEventListener('click', handleClick);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('scroll', handleClick);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
