const _temp0 = require("../amount-filter.js");
const _temp1 = require("../back.js");
const _temp2 = require("../broadcast-renew.js");
const _temp3 = require("../campobet-bnr-video.js");
const _temp4 = require("../campobet-cashbox-default-amount.js");
const _temp5 = require("../campobet-cashbox-frame-amount.js");
const _temp6 = require("../campobet-checked-wb.js");
const _temp7 = require("../campobet-close-login-popup.js");
const _temp8 = require("../campobet-multiple-quick-deposit.js");
const _temp9 = require("../campobet-payment-icon.js");
const _temp10 = require("../campobet-scroll-into-view.js");
const _temp11 = require("../campobet-slider.js");
const _temp12 = require("../campobet-splider.js");
const _temp13 = require("../campobet-toggle-cta-color.js");
const _temp14 = require("../category-splider.js");
const _temp15 = require("../clear-first-login.js");
const _temp16 = require("../close-from-frame.js");
const _temp17 = require("../close-sidebar.js");
const _temp18 = require("../count-tooltip-position.js");
const _temp19 = require("../date-picker.js");
const _temp20 = require("../datetime-filter.js");
const _temp21 = require("../get-local-storage.js");
const _temp22 = require("../get-state-params-to-scope-old.js");
const _temp23 = require("../input-bankid.js");
const _temp24 = require("../linda-sport-widget-v3-v2.js");
const _temp25 = require("../linda-sportbook.js");
const _temp26 = require("../milti-limit-suggestions.js");
const _temp27 = require("../refresh-user-on-paysystem-change.js");
const _temp28 = require("../scroll-element-to-top.js");
const _temp29 = require("../set-autofocus.js");
const _temp30 = require("../set-first-login.js");
const _temp31 = require("../set-local-storage.js");
const _temp32 = require("../smart-btn.js");
const _temp33 = require("../to-top-on-state-change.js");
const _temp34 = require("../track.js");
const _temp35 = require("../zimpler-frame.js");
const _temp36 = require("../balance-history/balance-history-filter-option.js");
const _temp37 = require("../balance-history/balance-history-filter-type.js");
const _temp38 = require("../balance-history/balance-history-selected-filters.js");
const _temp39 = require("../balance-history/balance-history-service.js");
const _temp40 = require("../balance-history/balance-history.js");

const _temp41 = require("../batman-promo-banner/batman-promo-banner.js");

const _temp42 = require("../campobet-collections/campobet-collections-buy.js");
const _temp43 = require("../campobet-collections/campobet-collections-item.js");
const _temp44 = require("../campobet-collections/campobet-collections-service.js");
const _temp45 = require("../campobet-collections/campobet-collections.js");

const _temp46 = require("../campobet-img/campobet-img.js");

const _temp47 = require("../pgw-payments-page/pgw-payments-page.js");

const _temp48 = require("../smart-banner/smart-banner-btn.js");
const _temp49 = require("../smart-banner/smart-banner-qr.js");

module.exports = {
  "amount-filter": _temp0,
  "back": _temp1,
  "broadcast-renew": _temp2,
  "campobet-bnr-video": _temp3,
  "campobet-cashbox-default-amount": _temp4,
  "campobet-cashbox-frame-amount": _temp5,
  "campobet-checked-wb": _temp6,
  "campobet-close-login-popup": _temp7,
  "campobet-multiple-quick-deposit": _temp8,
  "campobet-payment-icon": _temp9,
  "campobet-scroll-into-view": _temp10,
  "campobet-slider": _temp11,
  "campobet-splider": _temp12,
  "campobet-toggle-cta-color": _temp13,
  "category-splider": _temp14,
  "clear-first-login": _temp15,
  "close-from-frame": _temp16,
  "close-sidebar": _temp17,
  "count-tooltip-position": _temp18,
  "date-picker": _temp19,
  "datetime-filter": _temp20,
  "get-local-storage": _temp21,
  "get-state-params-to-scope-old": _temp22,
  "input-bankid": _temp23,
  "linda-sport-widget-v3-v2": _temp24,
  "linda-sportbook": _temp25,
  "milti-limit-suggestions": _temp26,
  "refresh-user-on-paysystem-change": _temp27,
  "scroll-element-to-top": _temp28,
  "set-autofocus": _temp29,
  "set-first-login": _temp30,
  "set-local-storage": _temp31,
  "smart-btn": _temp32,
  "to-top-on-state-change": _temp33,
  "track": _temp34,
  "zimpler-frame": _temp35,
  "balance-history":   {
    "balance-history-filter-option": _temp36,
    "balance-history-filter-type": _temp37,
    "balance-history-selected-filters": _temp38,
    "balance-history-service": _temp39,
    "balance-history": _temp40
  },
  "batman-promo-banner":   {
    "batman-promo-banner": _temp41
  },
  "campobet-collections":   {
    "campobet-collections-buy": _temp42,
    "campobet-collections-item": _temp43,
    "campobet-collections-service": _temp44,
    "campobet-collections": _temp45
  },
  "campobet-img":   {
    "campobet-img": _temp46
  },
  "pgw-payments-page":   {
    "pgw-payments-page": _temp47
  },
  "smart-banner":   {
    "smart-banner-btn": _temp48,
    "smart-banner-qr": _temp49
  }
}