import { filter } from 'rxjs/operators';

(function () {
  const directive = { name: 'campobetSplider' };

  controller.$inject = ['config', '$timeout', 'system$'];

  function controller(_config, $timeout, _system$) {
    function link(scope, element, attrs) {
      if (typeof Splide !== 'function') {
        return;
      }

      scope.spliderInstance;

      const { collectionKey, config, maxSlidesCount, hasVariableWidth } = scope.$eval(attrs[directive.name]);
      function slidesCountArray(size) {
        let x = [];
        for (let i = 0; i < size; ++i) {
          x[i] = i;
        }
        return x;
      }
      const buildSlider = () => {
        $timeout(() => {
          // this option is for only items with variable auto width
          // to make arrows enabling/disabling work correctly we need to set explicit width to slides with inline styles
          if (hasVariableWidth) {
            element[0].querySelectorAll('.splide__slide').forEach((slide) => slide.style.width = Math.round(slide.offsetWidth + 1) + 'px');
          }
          scope.spliderInstance = new Splide(element[0], config);
          scope.spliderInstance.mount( window.splide.Extensions );
          if(maxSlidesCount) {
            const slidesArr = slidesCountArray(scope.spliderInstance.length);
            const toRemoveArr = slidesArr.slice(maxSlidesCount, scope.spliderInstance.length + 1);
            scope.spliderInstance.remove(toRemoveArr);
          }
        });
      };

      const renewSlider = () => {
        if (scope.spliderInstance) {
          destroySlider();
        }

        buildSlider();
      };

      const subscription = _system$
        .pipe(
          filter(({ action }) => ['login', 'logout'].includes(action))
        )
        .subscribe(renewSlider);

      const destroySlider = () => {
        subscription.unsubscribe();
        scope.spliderInstance.destroy(true);
        scope.$watchCollectionspliderInstance = null;
      };

      scope.$watchCollection(collectionKey, (collection) => {
        if (collection && collection.length) {
          renewSlider();
        }
      });

      scope.slideTo = (index, event, selector) => {
        scope.spliderInstance.go(index);
        [...document.querySelectorAll(selector)].forEach((el) => el.classList.remove('is-current'));
        event.target.classList.add('is-current');
      }

      renewSlider();

      scope.$on('$destroy', destroySlider);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
