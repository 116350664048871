(function () {
  'use strict';

  controller.$inject = [
    '$scope',
    'paysystem'
  ];

  const cache = {}

  function controller(
    $scope,
    _paysystem
  ) {

    const PGW_URL = {
      ORIGIN: 'https://app.pgway.com/',
      PATHNAME: 'api/widget/publicPaymentMethods'
    }
    $scope.collection = [];
    $scope.type = '';

    async function fetchPayMethods (params = {}) {
      const defaults = {
        country: 'SE',
        currency: 'SEK',
        type: 'deposit',
        merchantKey: '67406616-4233-4eae-9137-8a236762432e'
      }

      params = Object.assign({}, defaults, params)

      const qParams = new URLSearchParams(params).toString()

      if(cache[qParams]) {
        $scope.collection = cache[qParams]

        return
      }

      const pgwUrl = new URL(`${PGW_URL.ORIGIN}${PGW_URL.PATHNAME}?${qParams}`)

      try {
        const result = await fetch(pgwUrl)
        const data = await result.json()

        $scope.collection = data.map(
          (item) => Object.assign(
            item, {
              purpose: params.type
            }
          )
        )

        cache[qParams] = $scope.collection
      } catch (e) {
        console.error(e)
      }
    }

    this.$onChanges = () => {
      const config = {};

      if (this.type) {
        config.type = this.type;
        $scope.type = this.type;
      }
      if (this.currency) config.currency = this.currency;
      if (this.country) config.country = this.country;
      if (this.category && config.type === 'deposit') {
        config.payment_category = this.category;
      }
      if (config.country && !config.currency) {
        return;
      }

      fetchPayMethods(config)

      $scope.preloader = true;
    };
  }

  new Controller('pgwBox', controller, {
    type: '<',
    currency: '<',
    country: '<',
    category: '<',
  });
})();

(function () {
  'use strict';
  new Batman('pgwBankingBox1', 'pgwBox');
})();
