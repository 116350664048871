const aliases = {
  home: {
    page: true,
    name: 'app.root.home',
  },
  promo: {
    page: true,
    name: 'app.root.promo',
  },
  history: {
    page: true,
    name: 'app.root.account.balance-history',
  },
  bonuses: {
    page: true,
    name: 'app.root.account.bonuses',
    private: true,
  },
  login: {
    page: false,
    name: 'bankid',
  },
  registration: {
    page: false,
    name: 'bankid',
  },
  paynplay: {
    page: false,
    name: 'bankid',
  },
  deposit: {
    page: false,
    name: 'cashbox',
  },
  realGame: {
    page: true,
    name: 'app.root.real',
  },
  initialLimitPage: {
    page: true,
    name: 'app.root.deposit-page',
  },
  initialTimeLimitPage: {
    page: true,
    name: 'app.root.time-page',
  },
  missedData: {
    page: true,
    name: 'app.root.missedData',
  },
  notFound: {
    page: true,
    name: 'app.root.not-found',
  },
  depositLimits: {
    page: true,
    name: 'app.root.account.responsible-gaming',
  },
  casino: {
    page: true,
    name: 'app.root.c.casino',
  },
  liveCasino: {
    page: true,
    name: 'app.root.c1.live_casino',
  },
  sport: {
    page: true,
    name: 'app.root.sportpage.prelive',
  },
  live: {
    page: true,
    name: 'app.root.sportpage.live',
  },
  trophyHall: {
    page: true,
    name: 'app.root.trophy.hall'
  },
  verification: {
    page: true,
    name: 'app.root.account.verification',
  }
};

export {aliases};

