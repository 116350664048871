(function () {
  controller.$inject = ['$scope', 'promo', 'user$', 'user', 'system$'];
  function controller($scope, _promo, _user$, _user, _system$) {
    $scope.collection = [];

    this.$onChanges = function () {
      $scope.preloader = true;

      if (this.category && this.category.length) {
        $scope.category = this.category;
      }

      getCollection($scope.category);
    };

    function checkWeekend(date = new Date()) {
      return (date.getDay() % 6 === 0) || date.getDay() === 5;
    }

    function getNewCategory(category) {
      if(_user.status && ((_user.profile.profile.is_fdb_made ||
          _user.profile.profile.is_first_deposit_made) ||
        _user.profile.active_bonus > 0)
      ) {
        return checkWeekend() ? `${category}_weekend` : `${category}_weekly`;
      }

      return category
    }

    const getCollection = category => {

      const params = {
        category: this.weekly ? getNewCategory(category) : category,
      }
      _promo
        .list(params)
        .then((answer) => {
          $scope.collection = answer.result;
          // if(_user.status && _user.profile.profile.trigger_codes) {
          //   $scope.collection = answer.result.filter( item => !item.trigger_codes || (item.trigger_codes === _user.profile.profile.trigger_codes));
          // } else {
          //   $scope.collection = answer.result;
          // }

        })
        .finally(() => {
          $scope.preloader = false;
        });
    }


    _user$.subscribe((m) => {
      $scope.preloader = true;
      getCollection($scope.category);
    });
  }

  new Controller('promoBanner', controller, {
    category: '<',
    count: '<',
    codes: '<',
    weekly: '<'
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanPromoBanner${i}`, 'promoBanner');
  });
})();

