import {filter} from "rxjs/operators";

(function () {
  'use strict';

  const directive = { name: 'campobetBnrVideo' };

  controller.$inject = ['splash$'];

  function controller(_splash$ ) {
    function link(scope) {
      const firstVideo = document.querySelector('#pre-video');
      const contentBlock = document.querySelector('.main-banner__flex');
      const isSplashLoaded = document.body.classList.contains('loaded');
      const firstVideoEndedHandler =  () => {
        firstVideo.removeEventListener("ended", firstVideoEndedHandler);
        firstVideo.remove();
        contentBlock.classList.add('fade-in');
      }
      const videoPlayHandler = () => {
        firstVideo.play();
        contentBlock.classList.add('fade-in');
      }

      firstVideo.addEventListener("ended", firstVideoEndedHandler);

      _splash$
        .pipe(
          filter((m) => m === 'loaded')
        )
        .subscribe(videoPlayHandler);

      if (isSplashLoaded) queueMicrotask(videoPlayHandler);

      scope.$on('$destroy', () => {
        firstVideo?.removeEventListener("ended", firstVideoEndedHandler)
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
