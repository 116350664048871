(function () {
  'use strict';

  const directive = { name: 'campobetCashboxFrameAmount' };

  controller.$inject = ['contentBuffer'];

  function controller(_contentBuffer) {
    function link(scope) {
      const pc = _contentBuffer.read('popup');
      if (pc.amount) {
        scope.formData.amount = pc.amount;
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
