// Docs: https://sb2widgetsstatic-altenar2-stage.biahosted.com/docs/index.html?path=/docs/labs-sportsbook--docs
import sport from '../../app/service/domain/sport.js';
import config from '../../app/service/configs/config.js';
import popup from '../../app/service/domain/popup.js';
import user$ from '../../app/service/rx/user$.js';
import system$ from '../../app/service/rx/system$.js';
import timer$ from '../../app/service/rx/timer$.js';
import state from '../../app/service/domain/state.js';
import banner from '../../app/service/domain/banner.js';
import sportBannerConfig from '../../app/service/domain/sportBannerConfig.js';
import system from '../../app/service/domain/system.js';
import analytics from '../../app/service/analytics/analytics.js';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  const name = 'lindaSportbook';

  controller.$inject = [
    '$state',
    'sport',
    'config',
    'popup',
    'user$',
    'system$',
    'timer$',
    'state',
    'banner',
    'sportBannerConfig',
    'system',
    'analytics',
    '$location',
    '$interval',
    '$window',
    '$log',
  ];

  function controller(
    $state,
    _sport,
    _config,
    _popup,
    _user$,
    _system$,
    _timer$,
    _state,
    _banner,
    _sportBannerConfig,
    _system,
    _analytics,
    _location,
    $interval,
    $window,
    $log,
  ) {
    function link(scope, element) {
      const container = element[0];
      const destroy$ = new Subject();

      const routeChangeEvents = [];
      // TODO: refactor
      function getStateParams() {
        if (scope.force) {
          clearUrl();
          scope.force = false;

          return {};
        }

        const params = $state.params;

        delete params.sportId;
        delete params.oddIds;
        delete params.categoryIds;
        delete params.eventId;
        delete params.championshipIds;

        params.oddIds = params.betslipoddids
          ? [...params.betslipoddids?.split(',')].map(e => parseInt(e))
          : undefined;
        params.championshipIds = params.champids
          ? [...params.champids?.split(',')].map(e => parseInt(e))
          : undefined;
        params.categoryIds = params.catids
          ? [...params.catids?.split(',')].map(e => parseInt(e))
          : undefined;
        params.eventId = parseInt(params.eventid) || undefined;
        params.sportId = parseInt(params.sportids) || undefined;

        if (params.championshipIds?.length) params.page = 'championship';

        return params || {};
      }

      function initSportbook(container, params, sportbookParams) {
        const ident = $interval(() => {
          if ($window.altenarWSDK) {
            $window.altenarWSDK.init(params);
            $window.ASB = $window.altenarWSDK.addSportsBook({
              props: sportbookParams,
              container: container,
            })

            $interval.cancel(ident);
          }
        }, 50);
      }

      const parseEvent = e => {
        const result = {};

        result.eventid =
          e.eventId ||
          e?.event?.id;

        result.champids =
          e.champId ||
          e.championshipIds?.join(',') ||
          e?.championship?.id;

        result.sportids = e.sportId;
        result.page = e.page;

        result.catids =
          e.catId ||
          e.categoryIds?.join(',');

        return result;
      }

      const updateUrl = e => {
        const params = parseEvent(e);
        _location.search(params);
      }

      const clearUrl = () => {
        _location.search({});
      }

      const getParams = () => {
        return Object.assign(
          {},
          {
            onSignInButtonClick: () => {
              _state.goto('login');
            },
            onInsufficientBalance: () => {
              if (!_config.sport.insufficientbalancecallback) return;
              _analytics.send('sport-altenar', 'balance', 'insufficient');
              _popup.open({ name: 'sport-low-balance' });
            },
            onOddSelect: () => {
              _analytics.send('sport-altenar', 'odds', 'select');
            },
            onBetHistoryButtonClick: () => {
              _analytics.send('sport-altenar', 'click', 'history');
            },
            onBetPlacement: () => {
              _analytics.send('sport-altenar', 'bet', 'new');
            },
            onBetPlacementError: () => {
              _analytics.send('sport-altenar', 'bet', 'error');
            },
            onBetReservation: () => {
              _analytics.send('sport-altenar', 'bet', 'reserved');
            },
            onCouponSelect: () => {
              _analytics.send('sport-altenar', 'coupon', 'select');
            },
            onGoBack: () => {
              if (!routeChangeEvents?.length) {
                $window.altenarWSDK.set({
                  page: 'overview'
                })
                clearUrl();
              } else {
                routeChangeEvents.splice(-1);
                updateUrl(routeChangeEvents[routeChangeEvents.length - 1] || {});
              }

              _analytics.send('sport-altenar', 'page', 'go-back');
            },
            onHorseRaceSelect: () => {
              _analytics.send('sport-altenar', 'select', 'horse-racing');
            },
            onPrintOddsClick: () => {
              _analytics.send('sport-altenar', 'odds', 'print');
            },
            onRouteChange: (e) => {
              if(Object.keys(e).length === 1 && e.page === 'live') {
                scope.force = true;
                $state.go('app.root.sportpage.live')
              }else if (Object.keys(e).length === 1 && e.page === 'overview') {
                scope.force = true;
                $state.go('app.root.sportpage.prelive');
              } else {
                routeChangeEvents.push(e);
                updateUrl(e)
              }
              _analytics.send('sport-altenar', 'page', `${e.page}${e.sportId || ''}`);
            },
            onShareCode: () => {
              _analytics.send('sport-altenar', 'code', 'share');
            },
            onRouterLoad,
            routerType: "memory",
            page: 'overview'
          },
          getStateParams()
        );
      }

      async function init() {
        const { result: { launch_options } } = await _sport.launch({ code: 'sport' });
        const sportbookParams = getParams();

        launch_options.integration = 'campose';
        launch_options.culture = launch_options.lang;

        if ($state.params.lang === 'ca') {
          launch_options.oddsFormat = 'american';
        }

        initSportbook(
          container,
          launch_options || {},
          sportbookParams
        );

        if (launch_options.token.length > 0) {
          $window.altenarWSDK.set({
            token: launch_options.token,
          });
        }
      }

      function onRouterLoad() {
        container.classList.remove('loading');
      }

      _system$
        .pipe(
          takeUntil(destroy$),
          filter(({ action }) => action === 'login')
        )
        .subscribe(() => {
          _sport
            .launch({ code: 'sport' })
            .then(({result: { launch_options }}) => {
              $window.altenarWSDK.set({
                token: launch_options.token,
              });
            })
            .catch((e) => $log.error(e));
        });

      _system$
        .pipe(
          takeUntil(destroy$),
          filter(({ action }) => action === 'logout')
        )
        .subscribe(() => {
          $window.altenarWSDK.set({
            token: '',
          });

          $window.ASB.remove()
        });

      container.classList.add('loading');

      init();

      scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        const from = fromState.url.split('?')[0];
        const to = toState.url.split('?')[0];
        const params = getStateParams();

        if(from !== to || params.sportId) {
          $window?.altenarWSDK?.set(params);
        }
      });


      scope.$on('$destroy', () => {
        $window?.ASB?.remove();

        destroy$.next();
        destroy$.unsubscribe();
      });
    };

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(name, controller);
})();

