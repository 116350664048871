(function () {
  'use strict';

  const directive = {
    name: 'multiLimitSuggestions'
  };

  controller.$inject = [];

  class valuesPair {
    constructor (value, oldValue, type) {
      this.value = value;
      this.oldValue = oldValue;
      this.type = this.getChangeType(this.value, this.oldValue);
    }

    getChangeType (newValue, oldValue) {
      if(!oldValue && !newValue) return;
      if(oldValue === newValue) return 'notChanged';
      if(oldValue && !newValue) return 'cleared';

      return 'changed';
    }

    valueOf () {
      return this.value;
    }
  }

  const MIN_LIMIT = 100;

  function controller() {
    function link( scope, element, attrs ) {
      function clearSuggestions () {
          scope.suggestions.day.value = undefined;
          scope.suggestions.week.value = undefined;
          scope.suggestions.month.value = undefined;
      }
      function suggestedOrMin(current, min) {
        return current < min ? min : current;
      }
      // TODO: needs refactor
      function handleSuggestions ({day, week, month}) {
        if (!scope.suggestions.trigger && !day.oldValue && day.value ) {
          scope.suggestions.trigger = true;
          scope.suggestions.day.trigger = true;

        }
        if (!scope.suggestions.trigger && !week.oldValue && week.value ) {
          scope.suggestions.trigger = true;
          scope.suggestions.week.trigger = true;

        }
        if (!scope.suggestions.trigger && !month.oldValue && month.value ) {
          scope.suggestions.trigger = true;
          scope.suggestions.month.trigger = true;
        }

        if(scope.suggestions.day.trigger && day.value) {
          scope.suggestions.day.value = suggestedOrMin(day.value, MIN_LIMIT);
          scope.suggestions.week.value = suggestedOrMin(Math.floor(day.value * 7), MIN_LIMIT);
          scope.suggestions.month.value = suggestedOrMin(Math.floor(day.value * 30));
        }
        if(scope.suggestions.week.trigger && week.value) {
          scope.suggestions.day.value = suggestedOrMin(Math.floor(week.value / 7), MIN_LIMIT);
          scope.suggestions.week.value = suggestedOrMin(week.value, MIN_LIMIT);
          scope.suggestions.month.value = Math.floor(week.value / 7 * 30);
        }
        if(scope.suggestions.month.trigger && month.value) {
          scope.suggestions.day.value = suggestedOrMin(Math.floor(month.value / 30), MIN_LIMIT);
          scope.suggestions.week.value = suggestedOrMin(Math.floor(month.value / 30  * 7), MIN_LIMIT);
          scope.suggestions.month.value = suggestedOrMin(month.value, MIN_LIMIT);
        }

        if (scope.suggestions.day.trigger && day.type === 'cleared' ) {
          scope.suggestions.trigger = false;
          scope.suggestions.day.trigger = false;
          clearSuggestions()

        }
        if (scope.suggestions.week.trigger && week.type === 'cleared' ) {
          scope.suggestions.trigger = false;
          scope.suggestions.week.trigger = false;
          clearSuggestions()

        }
        if (scope.suggestions.month.trigger && month.type === 'cleared' ) {
          scope.suggestions.trigger = false;
          scope.suggestions.month.trigger = false;
        }
      }

      scope.suggestions = {
        day: {
          trigger: false,
          value: undefined,
        },
        week: {
          trigger: false,
          value: undefined,
        },
        month: {
          trigger: false,
          value: undefined,
        },
        trigger: false
      };

      const fieldsToWatch = scope.formData.update_multi_limits
        .map((_, i) => `formData.update_multi_limits[${i}].amount`);

      scope.$watchGroup(fieldsToWatch, (
        [dayNew, weekNew, monthNew],
        [dayOld, weekOld, monthOld],
      ) => {
        handleSuggestions({
          day: new valuesPair(dayNew, dayOld) ,
          week: new valuesPair(weekNew, weekOld),
          month: new valuesPair(monthNew, monthOld)
        })
      });
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );
})();
