import playgrounds from '../../../app/service/gamification/playgrounds.js';
import popup from '../../../app/service/domain/popup.js';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

(function () {
  controller.$inject = ['$q', 'playgrounds', 'system$', 'popup', 'account'];

  const SERVICE_NAME = 'campobetCollectionServices';

  const COLLECTION_STATUS = {
    locked: 'locked',
    in_progress: 'in_progress',
    reward_taken: 'reward_taken',
  };

  const SUBCARD_STATUS = {
    opened: 'opened',
    closed: 'closed',
  };

  const TOTAL_SUBCARDS = 10;

  const collection = [];
  const collection$ = new BehaviorSubject([]);

  function controller($q, playgrounds, system$, _popup, _account) {
    function buyItem(item) {
      const id = item.id;
      const currentAlias = item.currentCardAlias;

      return playgrounds.buy({ id }).then(() => {
        const nextCollection = currentAlias === 'em_2024' ? collection[1] : collection.find((item) => item.status === COLLECTION_STATUS.locked);

        fetchCollection()
          .catch(() => {})
          .finally(() => {
            const currentCollection = collection.find((item) => item.alias === currentAlias);

            _popup.close();
            _popup.open({
              name: 'trophy-congratulations',
              content: {
                subcard: item,
                title: currentCollection.alias,
                currentAlias,
                currentCollection,
                nextCollection,
              },
            });

            _account.info({ nocache: true });
          });
      });
    }

    function countOpenedSubcards(list) {
      const subcardList = list.filter((subcard) => subcard.status === SUBCARD_STATUS.opened) || {};
      return subcardList.length;
    }

    function getPrevCollectionName(collection, index) {
      if (index === 0) {
        return;
      }
      return collection[index - 1].title;
    }

    function setCollection(newCollection) {
      collection.length = 0;
      collection.push(
        ...newCollection.map((card, index) => ({
          ...card,
          openedCards: countOpenedSubcards(card.list),
          prevCollectionName: getPrevCollectionName(newCollection, index),
        })),
      );
    }

    function fetchCollection() {
      return playgrounds.collection().then(({ result }) => {
        collection.length = 0;
        setCollection(result);
        collection$.next(collection);
      });
    }

    const systemSubscription = system$
      .pipe(
        filter(({ action }) => ['login', 'logout'].includes(action))
      )
      .subscribe(() => fetchCollection());

    fetchCollection();

    return {
      collection,
      collection$,
      buyItem,
    };
  }

  app.service(SERVICE_NAME, controller);
})();
