(function () {
  'use strict';

  const directive = { name: 'countTooltipPosition' };

  controller.$inject = ['$timeout'];

  function controller($timeout) {
    function link(scope, element, attrs) {
      const initialTooltipValues = [];

      let initialTopValue = 11;
      const tooltipItemDelta = 52;
      const tooltipBlockDelta = 19;

      [...element[0].querySelectorAll('.sidemenu-body__block')].forEach((block, i) => {
        initialTopValue += tooltipBlockDelta;

        [...block.querySelectorAll('.sidemenu-item')].forEach((item, j) => {
          if (i === 0 && j === 0) {
            initialTooltipValues.push(initialTopValue);
            return;
          }

          initialTopValue += tooltipItemDelta;
          initialTooltipValues.push(initialTopValue);
        });
      });

      [...element[0].querySelectorAll('.sidemenu-item')].forEach((el, index) => {
        el.style.setProperty('--tooltip-top', initialTooltipValues[index]);
      });

      function getScrollPosition(e) {
        const scrollTopVal = element.scrollTop();

        [...element[0].querySelectorAll('.sidemenu-item')].forEach((el, index) => {
          el.style.setProperty('--tooltip-top', initialTooltipValues[index] - scrollTopVal);
        });
      }

      element[0].addEventListener('scroll', getScrollPosition);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('scroll', getScrollPosition);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
