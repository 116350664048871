(function () {
    'use strict';

    app.filter('amountFormatter', function () {
      return function (str) {
        if (str == null) return '';
        if (typeof str === "string") str = parseFloat(str.replace(',', '.'));
        if ( isNaN(str) ) return ''; //- to check if string can not parse
        return str.toLocaleString('sv-SE', {maximumFractionDigits: 2})
      };
    })
  })();

