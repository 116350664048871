import balanceHistoryService from './balance-history-service.js';
import getUrlString from '../../../app/service/utils/get-url-string.js';

(function () {
  'use strict';

  const COMPONENT_NAME = 'balanceHistory';

  controller.$inject = [
    '$scope',
    'balanceHistoryService',
    '$httpParamSerializer',
    '$timeout'
  ];

  function controller (
    scope,
    _balanceHistoryService,
    $httpParamSerializer,
    $timeout
  ) {
    const PAGE_SIZE = 50;

    scope.preloader = true;
    scope.currentPage = 1;
    scope.totalPages = undefined;
    scope.transactions = [];
    scope.initDateBegin = new Date();

    function setTransactions (transactions) {
      scope.transactions = transactions;
    }

    function appendTransactions (transactions) {
      scope.transactions.push(...transactions);
    }

    function fetchTransactions (selectedFilterOptions = {}) {
      scope.preloader = true;
      scope.currentPage = 1;
      scope.totalPages = undefined;

      const filters = cleanFilters(selectedFilterOptions)

      scope.todayTransactions = new Date(filters.dateBegin).setHours(0,0,0,0) === new Date(new Date() - 1).setHours(0,0,0,0)
        || !filters.dateBegin;
      selectedFilterOptions.page = 1;

      const urlString = $httpParamSerializer(filters)

      _balanceHistoryService
        .transactions(`?${urlString}`)
        .then(({ result, page, total_pages }) => {
          setTransactions(result);
          scope.currentPage = page;
          scope.totalPages = total_pages;
        })
      .finally(() => {
        scope.preloader = false;
      })
    };

    function nextTransactions (selectedFilterOptions = {}) {
      scope.preloader = true;
      selectedFilterOptions.page = scope.currentPage + 1;

      const filters = cleanFilters(selectedFilterOptions)
      const urlString = $httpParamSerializer(filters)

      _balanceHistoryService
        .transactions(`?${urlString}`)
        .then(({ result, page, total_pages }) => {
          appendTransactions(result);
          scope.currentPage = page;
          scope.totalPages = total_pages;
        })
        .finally(() => {
          scope.preloader = false;
        })
    };

    function clearFilters () {
      scope.selectedOptions.status = []
    }

    function cleanFilters (filters) {
      const finalFilters = {
        count: PAGE_SIZE,
        dateBegin: new Date(new Date().setUTCHours(0,0,0,0)).toISOString(),
        dateEnd: new Date(new Date().setUTCHours(23,59,59,0)).toISOString(),
      }

      Object.assign(finalFilters, scope.selectedDates || {})

      for (const key in filters) {
        if (
          filters[key] && !Array.isArray(filters[key]) ||
          Array.isArray(filters[key]) && filters[key].length
        ) {
          finalFilters[key] = Array.isArray(filters[key])
            ? filters[key].join(',')
            : filters[key]
        }
      }

      return finalFilters;
    }

    scope.showMore = () => {
      nextTransactions(scope.selectedOptions)
    }

    scope.parseId = transactionString => {
      const re = new RegExp(/[^0-9]/g)

      return transactionString.replace(re, '')
    }

    scope.submit = fetchTransactions;

    scope.$on('toggle_isopen', () => {
      scope.isOpen = true;
    })

    fetchTransactions(scope.selectedOptions);
  }

  app.component(COMPONENT_NAME, {
    controller,
    template: app.getTU(COMPONENT_NAME),
    bindings: {},
  });
})();
