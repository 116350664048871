(function () {
  'use strict';

  const directive = {
    name: 'setFirstLogin',
  };

  function controller() {
    function link(scope, element, attrs) {
      const handleClick = () => {
        localStorage.setItem('firstLogin', true);
      };
      element[0].addEventListener('click', handleClick);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('scroll', handleClick);
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
