import balanceHistoryService from './balance-history-service.js';

(function () {
  'use strict';

  const DIRECTIVE_NAME = 'balanceHistorySelectedFilters';

  controller.$inject = [
    'balanceHistoryService',
    '$translate'
  ];

  function controller(
    _balanceHistoryService,
    $translate
  ) {
    function link(
      $scope,
      $element,
      $attrs,
      $ctrl,
      $transclude,
    ) {
      if (!$scope.ngModel) $scope.ngModel = [];

      function prepareAndbuildfilters () {
        const filters = prepareFilters();
        const slectedFilters = buildSelectedFilters(filters)
        const exFilters = document.querySelector('.filter-selected');

        exFilters?.remove();

        $transclude(
          $scope.$new(true),
          ($cloneElement, scope) => {
            const cloneElement = $cloneElement[0];

            cloneElement.append(slectedFilters)

            $element.after(cloneElement)
          })
      }

      function prepareFilters() {
        const filters = []

        for(const key of ['type', 'status', 'transactionType']) {
          if(Array.isArray($scope.ngModel[key])) {
            for (const value of $scope.ngModel[key]) {
              filters.push({type: key, value })
            }
          } else if (!!$scope.ngModel[key]) {
            filters.push({type: key, value: $scope.ngModel[key]})
          }
        }

        return filters;
      }

      function buildSelectedFilters (filters) {
        const container = document.createElement('div')
        container.classList.add('filter-selected')

        for (const filter of filters) {
          const hash = (Math.random() + 1)
            .toString(36)
            .substring(7);
          const checkbox = document.createElement('input')
          checkbox.setAttribute('type', 'checkbox');
          checkbox.setAttribute('id', `${filter.type}-${filter.value}-${hash}`)
          checkbox.setAttribute('value', filter.value);
          checkbox.setAttribute('hidden', 'true');

          const label = document.createElement('label');
          label.setAttribute('for', `${filter.type}-${filter.value}-${hash}`);
          label.classList.add('balance-filter__btn')

          const labelText = document.createTextNode(
            filter.type === 'type' ? $translate.instant(`filter.transaction.type.${filter.value}`) : filter.value
          );

          checkbox.addEventListener('click', () => {
            $scope.$emit('toggle_isopen', true);
          });

          label.append(labelText);

          container.append(checkbox, label);
        }

        return container;
      }

      $scope.$watch(
        'ngModel.type + ngModel.status + ngModel.transactionType',
        prepareAndbuildfilters,
        true
      )
    }

    return {
      require: 'ngModel',
      restrict: 'A',
      transclude: 'element',
      link,
      scope: {
        ngModel: '=',
      },
    };
  }

  app.directive(
    DIRECTIVE_NAME,
    controller
  );
})();
