import account from "../../app/service/domain/account.js";
import bonus from "../../app/service/domain/bonus.js";

(function () {
  'use strict';

  const directive = {
    name: 'refreshUserOnPaysystemChange',
  };

  controller.$inject = ['account', 'bonus'];

  function controller(_account, _bonus) {
    function link(scope) {
      scope.$watch('paysystem', function () {
        if (Object.keys(scope.paysystem).length) {
          _account.info({ nocache: true });
          _bonus.list();
        }
      });
    }

    return {
      restrict: 'A',
      link: link,
    };
  }

  app.directive(directive.name, controller);
})();
