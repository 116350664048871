import campobetCollectionServices from './campobet-collections-service.js';

(function () {
  'use strict';

  const directive = {
    name: 'campobetCollectionsBuy',
  };

  controller.$inject = ['$state', 'campobetCollectionServices'];

  function controller($state, campobetCollectionServices) {
    function link(scope) {
      scope.preloaderBuy = false;
      const currentCardAlias = $state.params.name;

      scope.buy = (card) => {
        scope.preloaderBuy = true;
        
        campobetCollectionServices
          .buyItem({ ...card, currentCardAlias })
          .catch(() => {})
          .finally(() => scope.preloaderBuy = false);
      }
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
