(function () {
  'use strict';

  const directive = { name: 'broadcastRenew' };


  controller.$inject = ['$rootScope'];

  function controller($rootScope) {
    function link(scope, element, attrs) {
      const actionName = attrs[directive.name];
      element[0].onclick = () => {
        $rootScope.$broadcast(actionName);
      };
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
