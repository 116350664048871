import '../../app/service/domain/user.js';
import '../../app/service/domain/popup.js';
import '../../app/service/domain/state.js';

(function () {
  'use strict';

  const directive = { name: 'smartBtn' };

  controller.$inject = ['user', 'popup', 'state', '$state'];
  function controller(_user, _popup, _state, $state) {
    function link(scope, element, attrs) {
      const config = scope.$eval(attrs[directive.name]);

      const handleClick = () => {
        if (!_user.status) {
          _state.goto('login');
        } else {
          switch (config.direction) {
            case 'popup':
              _popup.open({ name: config.alias, content: config.content });
              break;
            case 'page':
              $state.go(config.alias);
              break;
            default:
              break;
          }
        }
      };

      element[0].addEventListener('click', handleClick);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('scroll', handleClick);
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
